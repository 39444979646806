/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  searchType: localStorage.getItem('immomate.searchType') || 'text',
  searchResultId: null,
  results: null,
  searchTerm: '',
  typeFilter: [],
  immosFilter: [],
  group: null,
  appliedSearchTerm: '',
  isLoading: false,
};

export const slice = createSlice({
  name: 'search',
  initialState,
  reducers: {
    setSearchTerm: (state, { payload: { value } }) => {
      state.searchTerm = value;
    },
    search: () => {},
    searchSuccess: (state, { payload: { results, searchTerm, searchResultId } }) => {
      state.searchResultId = searchResultId;
      state.results = results;
      state.appliedSearchTerm = searchTerm;
    },
    setTypeFilter: (state, { payload: { value } }) => {
      state.typeFilter = value;
    },
    setImmosFilter: (state, { payload: { value } }) => {
      state.immosFilter = value;
    },
    setGroup: (state, { payload: { value } }) => {
      state.group = value;
    },
    setSearchType: (state, { payload: { value } }) => {
      state.searchType = value;
      localStorage.setItem('immomate.searchType', value);
    },
    loadSearchResult: (state) => {
      state.isLoading = true;
    },
    loadSearchResultSuccess: (state, {
      payload: {
        results, searchTerm, searchResultId, searchType, groupingTypes, documentClassIds, immos,
      },
    }) => {
      state.isLoading = false;
      state.searchResultId = searchResultId;
      state.results = results;
      state.searchTerm = searchTerm;
      state.appliedSearchTerm = searchTerm;
      state.searchType = searchType.toLowerCase();
      // eslint-disable-next-line prefer-destructuring
      state.group = groupingTypes?.[0] || null;
      state.typeFilter = documentClassIds || [];
      state.immosFilter = immos;
    },
    loadSearchResultFailure: (state) => {
      state.isLoading = false;
    },
    reset: () => initialState,
  },
});

export const { actions } = slice;

export default slice.reducer;
