import React from 'react';
import { useSelector } from 'react-redux';

import TableSettingsProvider from 'components/TableSettingsProvider';
import { COLUMN_LIST } from 'constants/fileList';

import { fileListSelectors as selectors } from 'redux/selectors';

import FilesTable from './FilesTable';

const FilesTableContainer = (props) => {
  const fileTypes = useSelector(selectors.typeFilterSelector);

  const columnList = [...COLUMN_LIST];

  if (fileTypes.length === 1) {
    columnList.push({
      type: 'METADATA',
      title: 'Metadata',
    });
  }

  return (
    <TableSettingsProvider tableId="scans" columns={columnList}>
      <FilesTable {...props} />
    </TableSettingsProvider>
  );
};

export default FilesTableContainer;
