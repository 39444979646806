import React, { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

import { Stack, Box } from '@mui/material';

import Layout from 'components/Layout';
import { useSelector, useDispatch } from 'react-redux';
import { searchSelectors as selectors } from 'redux/selectors';
import { searchActions as actions } from 'redux/actions';

import useDidMount from 'hooks/useDidMount';

import SearchCard from 'pages/SearchPage/SearchCard';
import DocsTypeFilter from './DocsTypeFilter';
import DocsGroupFilter from './DocsGroupFilter';
import ImmosFilter from './ImmosFilter';

import DocsFilterChips from './DocsFilterChips';
import SearchTypeSwitcher from './SearchTypeSwitcher';

import SearchBox from './SearchBox';

import useStyles from './SearchPage.styles';

const SearchPage = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();

  const results = useSelector(selectors.resultsSelector);
  const searchResultId = useSelector(selectors.searchResultIdSelector);
  const appliedSearchTerm = useSelector(selectors.appliedSearchTermSelector);
  const isLoading = useSelector(selectors.isLoadingSelector);

  useDidMount(() => {
    const resultId = searchParams.get('r');

    if (resultId) {
      dispatch(actions.loadSearchResult({ id: resultId }));
    } else {
      dispatch(actions.search());
    }
  });

  useEffect(() => {
    if (searchResultId) {
      searchParams.set('r', searchResultId);

      setSearchParams(searchParams);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchResultId]);

  const renderResults = () => {
    if (!results) {
      return null;
    }

    if (results.length === 0) {
      return <Box sx={{ margin: '16px' }}>No results</Box>;
    }

    return (
      <Stack spacing="16px" className={classes.resultList}>
        {results.map((result) => (
          <SearchCard key={result.file_item_id} result={result} searchTerm={appliedSearchTerm} />
        ))}
      </Stack>
    );
  };

  return (
    <Layout
      mainInnerClassName={classes.mainInner}
      filterChipsClassName={classes.filterChips}
      app="search"
      isInitialLoading={isLoading}
      searchBox={(
        <div className={classes.searchBoxContainer}>
          <Stack direction="row" spacing="16px" sx={{ alignItems: 'center' }}>
            <SearchBox />
            <ImmosFilter />
            <DocsTypeFilter />
            <DocsGroupFilter />
          </Stack>
          <SearchTypeSwitcher />
        </div>
      )}
      filterChips={<DocsFilterChips />}
    >
      {renderResults()}
    </Layout>
  );
};

export default SearchPage;
