import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';

import { searchActions as actions } from 'redux/actions';
import { searchSelectors as selectors } from 'redux/selectors';

const SearchTypeSwitcher = () => {
  const dispatch = useDispatch();

  const searchType = useSelector(selectors.searchTypeSelector);
  const searchTerm = useSelector(selectors.searchTermSelector);

  const handleChange = (event, newAlignment) => {
    if (newAlignment) {
      dispatch(actions.setSearchType({ value: newAlignment }));
      if (searchTerm) {
        dispatch(actions.search());
      }
    }
  };

  return (
    <ToggleButtonGroup
      size="small"
      sx={{ mt: '16px' }}
      color="primary"
      value={searchType}
      exclusive
      onChange={handleChange}
    >
      <ToggleButton value="text">Exact</ToggleButton>
      <ToggleButton value="vector">Contextual</ToggleButton>
      <ToggleButton value="hybrid">Hybrid</ToggleButton>
    </ToggleButtonGroup>
  );
};

export default SearchTypeSwitcher;
