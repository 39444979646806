const rootSelector = (state) => state.search;

export const searchTypeSelector = (state) => rootSelector(state).searchType;

export const searchTermSelector = (state) => rootSelector(state).searchTerm;

export const resultsSelector = (state) => rootSelector(state).results;

export const typeFilterSelector = (state) => rootSelector(state).typeFilter;

export const immosFilterSelector = (state) => rootSelector(state).immosFilter;

export const groupSelector = (state) => rootSelector(state).group;

export const appliedSearchTermSelector = (state) => rootSelector(state).appliedSearchTerm;

export const searchResultIdSelector = (state) => rootSelector(state).searchResultId;

export const isLoadingSelector = (state) => rootSelector(state).isLoading;
