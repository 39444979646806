import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { fileListSelectors as selectors } from 'redux/selectors';
import loadFiles from 'utils/loadFiles/loadFiles';

const useResolveAllFiles = () => {
  const { immoId, scanId } = useParams();
  const items = useSelector(selectors.itemsSelector) || [];
  const group = useSelector(selectors.subFolderSelector);
  const fileTypes = useSelector(selectors.typeFilterSelector);

  const hasMore = useSelector(selectors.hasMoreSelector);

  return async () => {
    if (!hasMore) {
      return items;
    }

    const allFiles = [];

    let offset = 0;

    // eslint-disable-next-line no-constant-condition
    while (true) {
      // eslint-disable-next-line no-await-in-loop
      const data = await loadFiles({
        immoId,
        subFolder: group,
        originId: scanId,
        fileTypes,
        searchTerm: '',
        limit: 300,
        offset,
      });

      allFiles.push(...data.files);

      if (!data.hasMore) {
        break;
      }

      offset += 300;
    }

    return allFiles;
  };
};

export default useResolveAllFiles;
