import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { NotificationManager as NM } from 'react-notifications';

import Dialog from '@mui/material/Dialog';

import request from 'api/request';
import { useDidMount } from 'hooks';

import useFilePreview from 'hooks/useFilePreview';
import { mainActions } from 'redux/actions';

export default function FileViewDialog({ fileItemId, pageNumber }) {
  const [url, setUrl] = useState();
  const { closeFilePreview } = useFilePreview();
  const dispatch = useDispatch();

  const handleClose = () => {
    closeFilePreview();
    setTimeout(() => {
      dispatch(mainActions.setHighlightedItemId({ value: fileItemId }));
    }, 0);
  };

  const handleResolveUrl = async () => {
    try {
      const { data } = await request({
        method: 'post',
        url: '/document-download-links/_generate',
        data: {
          file_item_id: fileItemId,
        },
      });
      setUrl(data.signed_link);
    } catch (e) {
      NM.error('Something went wrong during resolving the link');
    }
  };

  useDidMount(() => {
    handleResolveUrl();
  });

  const renderContent = () => {
    if (!url) {
      return null;
    }

    return (
      <iframe
        style={{
          width: '100%',
          height: 'calc(100vh - 64px)',
          border: 'none',
        }}
        title="document"
        src={pageNumber ? `${url}#page=${pageNumber}` : url}
      />
    );
  };

  return (
    <Dialog
      open
      maxWidth="xl"
      fullWidth
      disableRestoreFocus
      onClose={handleClose}
    >
      {renderContent()}
    </Dialog>
  );
}
