/* eslint-disable max-len */
import { actions as mainActions } from './main/mainReducer';
import { actions as fileCreationActions } from './files/filesCreation/filesCreationReducer';
import { actions as fileListActions } from './files/fileList/fileListReducer';
import { actions as inboxListActions } from './inbox/inboxList/inboxListReducer';
import { actions as inboxCreationActions } from './inbox/inboxCreation/inboxCreationReducer';
import { actions as fileSplittingActions } from './files/fileSplitting/fileSplittingReducer';
import { actions as userActions } from './user/userReducer';
import { actions as immoListActions } from './immos/immoList/immoListReducer';
import { actions as creationImmoDialogActions } from './immos/creationImmoDialog/creationImmoDialogReducer';
import { actions as currentImmoActions } from './currentImmo/currentImmoReducer';
import { actions as inboxItemSubDocsActions } from './inbox/inboxItemSubDocs/inboxItemSubDocsReducer';
import { actions as approvalListActions } from './approvals/approvalList/approvalListReducer';
import { actions as fileViewDialogActions } from './dialogs/fileViewDialog/fileViewDialogReducer';
import { actions as regenerateFileNameConfirmationDialogActions } from './dialogs/regenerateFileNameConfirmationDialog/regenerateFileNameConfirmationDialogReducer';
import { actions as searchActions } from './search/searchReducer';
import { actions as processingPopupActions } from './processingPopup/processingPopupReducer';

const { setIsGlobalLoading } = mainActions;

export {
  mainActions,
  fileCreationActions,
  fileListActions,
  fileSplittingActions,
  userActions,
  immoListActions,
  currentImmoActions,
  setIsGlobalLoading,
  inboxListActions,
  inboxCreationActions,
  inboxItemSubDocsActions,
  approvalListActions,
  creationImmoDialogActions,
  fileViewDialogActions,
  searchActions,
  processingPopupActions,
  regenerateFileNameConfirmationDialogActions,
};
