import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
  root: {
    position: 'fixed',
    top: 0,
    left: 0,
    height: '100%',
    width: '100%',
    backgroundColor: '#F8FAFD',
    display: 'grid',
    gridTemplateAreas: `
      "nav head"
      "nav search"
      "nav filterChips"
      "nav main"
    `,
    gridTemplateColumns: 'auto 1fr',
    gridTemplateRows: '64px minmax(56px, auto) auto 1fr',
    columnGap: 16,

    '&$noSearchBox': {
      gridTemplateAreas: `
        "nav head"
        "nav main"
        "nav main"
        "nav main"
      `,
    },
  },
  noSearchBox: {},
  progress: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
  },
  navigation: {
    gridArea: 'nav',
  },
  header: {
    gridArea: 'head',
    padding: 8,
    paddingRight: 16,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  search: {
    gridArea: 'search',
    paddingLeft: 8,
    paddingRight: 20,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  newBtnContainer: {
    padding: '0 16px',
  },
  foldersContainer: {
    gridArea: 'navp',
    padding: '16px 0',
  },
  main: {
    gridArea: 'main',
    margin: 8,
    marginRight: 20,
    minHeight: 0,
    minWidth: 0,
    position: 'relative',
  },
  editModeChip: {
    position: 'absolute',
    top: -16,
    left: '50%',
    transform: 'translateX(-50%)',
    zIndex: 10,
  },
  mainInner: {
    backgroundColor: '#fff',
    borderRadius: 16,
    height: '100%',
    border: '2px solid transparent',
  },
  editMode: {
    borderColor: 'rgba(250, 99, 42, 1)',
  },
  mainInnerScroll: {
    overflowY: 'auto',
    margin: '0 -5px',
    padding: '0 5px',
    height: '100%',
  },
  filterChips: {
    margin: 8,
    gridArea: 'filterChips',
  },
});

export default useStyles;
