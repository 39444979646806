/* eslint-disable max-len */
import { combineReducers } from 'redux';

import main from './main/mainReducer';
import filesCreation from './files/filesCreation/filesCreationReducer';
import fileList from './files/fileList/fileListReducer';
import inboxList from './inbox/inboxList/inboxListReducer';
import inboxCreation from './inbox/inboxCreation/inboxCreationReducer';
import fileSplitting from './files/fileSplitting/fileSplittingReducer';
import user from './user/userReducer';
import immoList from './immos/immoList/immoListReducer';
import creationImmoDialog from './immos/creationImmoDialog/creationImmoDialogReducer';
import currentImmo from './currentImmo/currentImmoReducer';
import inboxItemSubDocs from './inbox/inboxItemSubDocs/inboxItemSubDocsReducer';
import approvalList from './approvals/approvalList/approvalListReducer';
import fileViewDialog from './dialogs/fileViewDialog/fileViewDialogReducer';
import regenerateFileNameConfirmationDialog from './dialogs/regenerateFileNameConfirmationDialog/regenerateFileNameConfirmationDialogReducer';
import search from './search/searchReducer';
import processingPopup from './processingPopup/processingPopupReducer';

export default combineReducers({
  main,
  filesCreation,
  fileList,
  fileSplitting,
  user,
  immoList,
  currentImmo,
  inboxList,
  inboxCreation,
  inboxItemSubDocs,
  approvalList,
  creationImmoDialog,
  fileViewDialog,
  regenerateFileNameConfirmationDialog,
  search,
  processingPopup,
});
