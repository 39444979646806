import React, { useState, useRef, useLayoutEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { NotificationManager as NM } from 'react-notifications';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import { mainSelectors, fileListSelectors as selectors } from 'redux/selectors';
import { setIsGlobalLoading, fileListActions as actions } from 'redux/actions';

import request from 'api/request';
import useTableSettings from 'components/TableSettingsProvider/useTableSettings';
import ColumnVisibilitySelect from 'components/TableSettingsProvider/ColumnVisibilitySelect';
import ViewMetadataDialog from 'components/dialogs/ViewMetadataDialog';

import TableHeadCell from 'components/UIKit/TableHeadCell';

import useDownloadExportZip from 'hooks/export/useDownloadExportZip';
import useDocumentClassesById from 'hooks/documentClasses/useDocumentClassesById';

import FilesTableMenu from './FilesTableMenu';
import FileRaw from './FileRaw';

import useResolveAllFiles from './hooks/useResolveAllFiles';

// eslint-disable-next-line no-unused-vars
import useStyles from './FilesTable.styles';

const FilesTable = ({ disableBulkProcessingActions }) => {
  const dispatch = useDispatch();
  const tableContainerRef = useRef(null);
  const items = useSelector(selectors.itemsSelector) || [];
  const hasMore = useSelector(selectors.hasMoreSelector);
  const loading = useSelector(selectors.isLoadingSelector);
  const sort = useSelector(selectors.sortSelector);
  const typeFilter = useSelector(selectors.typeFilterSelector);

  const [selectedMetadataItem, setSelectedMetadataItem] = useState(null);
  const [processingKeyHack, setProcessingKeyHack] = useState(null);
  const { visibleColumns } = useTableSettings();
  const resolveAllFiles = useResolveAllFiles();
  const downloadExportZip = useDownloadExportZip();
  const documentClassesById = useDocumentClassesById() || {};

  const highlightedItemId = useSelector(mainSelectors.highlightedItemIdSelector);

  const getMetadataColumns = () => {
    if (
      !visibleColumns.find(({ type }) => type === 'METADATA')
      || typeFilter.length !== 1
      || !documentClassesById[typeFilter[0]]
    ) {
      return [];
    }

    return documentClassesById[typeFilter[0]].fields.filter(
      ({ name }) => name !== 'Summary (eng)' && name !== 'Summary (de)',
    );
  };

  const metadataColumns = getMetadataColumns();

  const handleMetadataDialogClose = () => {
    setSelectedMetadataItem(null);
  };

  const handleExport = async () => {
    dispatch(setIsGlobalLoading(true));

    try {
      const allFiles = await resolveAllFiles();

      const { data: task } = await request({
        method: 'post',
        url: 'files/_export_zip',
        data: {
          target_file_name: 'export',
          file_ids: allFiles.map((file) => file.id),
        },
      });
      await downloadExportZip(task.id, 'export');
    } catch (e) {
      NM.error('Something went wrong');
    }

    dispatch(setIsGlobalLoading(false));
  };

  const handleClassifyAllClick = async () => {
    dispatch(setIsGlobalLoading(true));

    try {
      const allFiles = await resolveAllFiles();

      const notClassifiedItems = allFiles.filter((item) => !item.ai_generated_type);

      const responses = await Promise.all(notClassifiedItems.map((item) => request({
        method: 'post',
        url: `/files/${item.id}/_classify`,
        data: {},
      })));
      setProcessingKeyHack(Math.random());

      responses.forEach((response) => {
        dispatch(actions.updateFile({ file: response.data.classified_file_item }));
      });

      NM.success('Classification complete');
    } catch (e) {
      NM.error('Something went wrong');
    }

    dispatch(setIsGlobalLoading(false));
  };

  const handleExtractMetadataAllClick = async () => {
    dispatch(setIsGlobalLoading(true));

    try {
      const allFiles = await resolveAllFiles();

      const notProcessedItems = allFiles.filter((item) => item.ai_generated_type && !item.ai_metadata);

      const responses = await Promise.all(notProcessedItems.map((item) => request({
        method: 'post',
        url: `/files/${item.id}/_extract_metadata`,
        data: {},
      })));
      setProcessingKeyHack(Math.random());

      responses.forEach((response) => {
        dispatch(actions.updateFile({ file: response.data }));
      });

      NM.success('Extracting metadata complete');
    } catch (e) {
      NM.error('Something went wrong');
    }

    dispatch(setIsGlobalLoading(false));
  };

  useLayoutEffect(() => {
    const tableEl = tableContainerRef.current;

    const handleScroll = () => {
      const bottom = tableEl.scrollHeight - tableEl.clientHeight;

      if (tableEl.scrollTop > bottom - 1000 && hasMore && !loading) {
        dispatch(actions.getFileListRequest({ loadMore: true }));
      }
    };

    tableEl.addEventListener('scroll', handleScroll);
    return () => {
      tableEl.removeEventListener('scroll', handleScroll);
    };
  }, [hasMore, loading, dispatch]);

  const getMetadataColumnMinWidth = (metadataColumn) => {
    if (metadataColumn.id === 'iban') {
      return 174;
    }

    if (metadataColumn.type === 'DATE') {
      return 140;
    }

    return 100;
  };

  const handleSortChange = (newSort) => {
    dispatch(actions.setSort({ sort: newSort }));
    dispatch(actions.getFileListRequest({}));
  };

  const renderColumn = (column) => {
    if (column.type === 'METADATA') {
      return metadataColumns.map(((metadataColumn) => (
        <TableHeadCell
          key={metadataColumn.id}
          sx={{ minWidth: getMetadataColumnMinWidth(metadataColumn) }}
          column={{ type: metadataColumn.id, isSortable: true, isMetadata: true }}
          sort={sort}
          onSort={handleSortChange}
        >
          {metadataColumn.name}
        </TableHeadCell>
      )));
    }

    return (
      <TableCell key={column.type} align={column.align} sx={{ minWidth: column.minWidth }}>
        {column.title}
      </TableCell>
    );
  };

  const handleMetadataUpdate = (newFileItem) => {
    dispatch(actions.updateFile({ file: newFileItem }));
    setSelectedMetadataItem(newFileItem);
  };

  return (
    <>
      <TableContainer sx={{ height: '100%' }} ref={tableContainerRef}>
        <Table stickyHeader style={{ padding: 16 }}>
          <TableHead>
            <TableRow>
              <TableCell
                style={{ paddingLeft: 0, paddingRight: 0, width: 40 }}
                colSpan={2}
              >
                Name
              </TableCell>
              {visibleColumns.map((column) => renderColumn(column))}
              <TableCell />
              <TableCell sx={{ width: 0 }} align="right">
                <ColumnVisibilitySelect />
                <FilesTableMenu
                  onClassifyAll={disableBulkProcessingActions ? undefined : handleClassifyAllClick}
                  onExtractMetadataAll={disableBulkProcessingActions ? undefined : handleExtractMetadataAllClick}
                  onExportZip={handleExport}
                />
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {items.map((item) => (
              <FileRaw
                key={processingKeyHack + item.id}
                item={item}
                metadataColumns={metadataColumns}
                highlighted={highlightedItemId === item.id}
                onViewMetadata={setSelectedMetadataItem}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {selectedMetadataItem && (
        <ViewMetadataDialog
          fileItem={selectedMetadataItem}
          onItemUpdate={handleMetadataUpdate}
          onClose={handleMetadataDialogClose}
        />
      )}
    </>
  );
};

export default FilesTable;
