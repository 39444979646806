import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import debounce from 'lodash/debounce';

import Checkbox from '@mui/material/Checkbox';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

import { searchActions as actions } from 'redux/actions';
import { searchSelectors as selectors } from 'redux/selectors';

import { useDidMount } from 'hooks';

import request from 'api/request';

import useStyles from './ImmosFilter.styles';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const DocsTypeFilter = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const selectedValue = useSelector(selectors.immosFilterSelector);

  const [suggestions, setSuggestions] = useState(null);

  const searchImmos = async (text) => {
    try {
      const { data: { immos } } = await request({
        method: 'post',
        url: '/immos/_search',
        data: {
          name: text,
          limit: 300,
        },
      });

      setSuggestions(immos);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }
  };

  const debouncedSearchImmos = debounce(searchImmos, 200);

  const handleInputChange = (event, newValue) => {
    debouncedSearchImmos(newValue);
  };

  const handleChange = async (e, newValue) => {
    dispatch(actions.setImmosFilter({ value: newValue }));
    dispatch(actions.search());
  };

  useDidMount(() => {
    searchImmos('');
  });

  return (
    <Autocomplete
      classes={{
        popper: classes.popper,
        tag: classes.tag,
      }}
      slotProps={{
        popper: {
          placement: 'bottom-start',
        },
      }}
      value={selectedValue}
      sx={{ width: '150px' }}
      size="small"
      multiple
      limitTags={1}
      options={suggestions || []}
      disableCloseOnSelect
      getOptionLabel={(option) => option.name}
      renderOption={(props, option, { selected }) => {
        const { key, ...optionProps } = props;
        return (
          <li key={key} {...optionProps}>
            <Checkbox
              icon={icon}
              checkedIcon={checkedIcon}
              style={{ marginRight: 8 }}
              checked={selected}
            />
            {option.name}
          </li>
        );
      }}
      renderInput={(params) => (
        <TextField {...params} label="Properties" placeholder="Properties" />
      )}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      onInputChange={handleInputChange}
      onChange={handleChange}
    />
  );
};

export default DocsTypeFilter;
