import React from 'react';
import { useSelector } from 'react-redux';
import { format } from 'date-fns';

import TableCell from '@mui/material/TableCell';

import { mainSelectors } from 'redux/selectors';

import FileTypeCell from 'components/FileTypeCell';
import TextCell from 'components/UIKit/TextCell';

const formatDate = (date) => (date ? format(new Date(date), 'dd.MM.yyyy') : '');

function bytesToMB(bytes) {
  return bytes / 1024 / 1024;
}

const FileCell = ({ item, columnType, onItemUpdate }) => {
  const selectedLanguage = useSelector(mainSelectors.languageSelector);

  const isEn = selectedLanguage === 'EN';

  const renderDescription = () => {
    if (isEn && item.summary) {
      return item.summary;
    }

    if (!isEn && item.summary_de) {
      return item.summary_de;
    }

    const aiMetadata = item.ai_metadata;

    if (!aiMetadata) {
      return null;
    }

    if (isEn) {
      return aiMetadata.metadata_list.find(({ name }) => name === 'Summary (eng)')?.value;
    }

    return aiMetadata.metadata_list.find(({ name }) => name === 'Summary (de)')?.value;
  };

  switch (columnType) {
    case 'DESCRIPTION': {
      const description = renderDescription();

      return <TextCell text={description} />;
    }
    case 'TYPE':
      return <FileTypeCell item={item} onItemUpdate={onItemUpdate} />;
    case 'PAGES':
      return <TableCell align="right">{item.pages_count}</TableCell>;
    case 'CREATOR':
      return <TableCell>{item.created_by}</TableCell>;
    case 'DATE_ADDED':
      return <TableCell>{formatDate(item.created_at)}</TableCell>;
    case 'SIZE':
      return (
        <TableCell align="right" sx={{ whiteSpace: 'nowrap' }}>
          {bytesToMB(Math.max(item.size, 0.1)).toFixed(3)}
          {' '}
          MB
        </TableCell>
      );
    default:
      return <TableCell />;
  }
};

export default FileCell;
