const rootSelector = (state) => state.fileList;

export const isLoadingSelector = (state) => rootSelector(state).isLoading;

export const itemsSelector = (state) => rootSelector(state).items;

export const totalItemsSelector = (state) => rootSelector(state).totalItems;

export const limitSelector = (state) => rootSelector(state).limit;

export const subFolderSelector = (state) => rootSelector(state).subFolder;

export const originIdSelector = (state) => rootSelector(state).originId;

export const sortSelector = (state) => rootSelector(state).sort;

export const itemTaskSelector = (itemId) => (state) => rootSelector(state).tasksPerItem[itemId];

export const searchTermSelector = (state) => rootSelector(state).searchTerm;

export const typeFilterSelector = (state) => rootSelector(state).typeFilter;

export const offsetSelector = (state) => rootSelector(state).offset;

export const hasMoreSelector = (state) => rootSelector(state).hasMore;
